<!-- 保函申请-在线申请 -->
<template>
  <div class="online">
    <div style="display: flex; justify-content: space-between">
      <div style="width: 78%">
        <div
          style="
            height: 40px;
            background: #039eee;
            font-size: 16px;
            font-family: SimHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 40px;
          "
        >
          <img
            src="../assets/订单 (1)@2x.png"
            alt
            style="
              width: 17px;
              height: 17px;
              vertical-align: text-bottom;
              margin-left: 13px;
            "
          />
          申请流程
        </div>
        <div class="cardTop-middle">
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/1@2x (1).png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/2@2x (1).png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/3@2x (1).png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/4@2x (1).png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
          <div style="width: 17%; height: 114px">
            <img
              src="../assets/5@2x (1).png"
              alt
              style="height: 100%; width: 100%"
            />
          </div>
        </div>
      </div>
      <div style="width: 20%">
        <div
          style="
            height: 40px;
            background: #039eee;
            font-size: 16px;
            font-family: SimHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 40px;
          "
        >
          <img
            src="../assets/帮助中心 (1)@2x.png"
            alt
            style="
              width: 17px;
              height: 17px;
              vertical-align: text-bottom;
              margin-left: 13px;
            "
          />
          帮助中心
        </div>
        <div style="background-color: #fff; height: 153px">
          <div style="margin-left: 30px">
            <img
              src="../assets/wire(1).png"
              alt
              style="width: 20px; height: 20px; vertical-align: text-bottom"
            />
            <el-button
              type="text"
              style="
                color: rgb(51, 126, 190);
                font-size: 16px;
                margin-left: 5px;
                letter-spacing: 3px;
              "
              @click="operationManual"
              >操作手册</el-button
            >
          </div>
          <div style="margin-left: 30px">
            <img
              src="../assets/电 话 (1)@2x.png"
              style="width: 20px; height: 20px; vertical-align: text-bottom"
              alt
            />
            <el-button
              type="text"
              style="
                color: rgb(51, 126, 190);
                font-size: 16px;
                margin-left: 5px;
                letter-spacing: 3px;
                cursor: default;
              "
              >客服电话：400-183-1833</el-button
            >
          </div>
          <div style="margin-left: 30px">
            <el-button
              type="text"
              style="
                color: rgb(51, 126, 190);
                font-size: 16px;
                margin-left: 5px;
                letter-spacing: 3px;
                cursor: default;
              "
              >业务咨询：15633598882</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        height: 40px;
        background: #039eee;
        font-size: 16px;
        font-family: SimHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        margin-top: 15px;
      "
    >
      <img
        src="../assets/订单 (1)@2x (1).png"
        alt
        style="
          width: 17px;
          height: 17px;
          vertical-align: text-bottom;
          margin-left: 13px;
        "
      />
      项目信息
    </div>
    <div
      style="
        height: 530px;
        overflow-y: auto;
        background-color: #fff;
        padding: 20px;
      "
    >
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="projectName" placeholder="项目名称"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="tenderee" placeholder="招标人"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="area" placeholder="所属区域">
            <el-option
              v-for="item in areaArr"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <div class="block">
            <el-date-picker
              v-model="date"
              type="date"
              placeholder="开标日期"
              style="width: 100%"
            ></el-date-picker>
          </div>
        </el-col>
        <el-col :span="7">
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </el-col>
      </el-row>
      <el-table :data="tableData" style="width: 100%; margin-top: 20px">
        <el-table-column
          align="center"
          type="index"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="biaoduanName"
          label="项目名称"
          width="500"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="profits_person"
          label="招标人"
          width="300"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="gua_money"
          label="保证金金额（元）"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="所属地区"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="bid_begin_date"
          label="开标日期"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="applyImmediately(scope.row)"
              >立即申请</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          style="text-align: center; margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.current"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="this.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
        ></el-pagination>
      </div>
      <div
        style="
          text-align: center;
          padding-top: 20px;
          margin-top: 20px;
          border-top: 1px solid #ccc;
        "
      >
        <el-button type="primary" @click="getBack">返回</el-button>
      </div>
    </div>
    <el-drawer
      title="保函类型：投标保函"
      :visible.sync="drawer"
      size="70%"
      @closed="closed"
    >
      <div style="color: red; margin-left: 20px">
        保费三十元每笔。
      </div>
      <div
        style="padding: 0 50px; display: flex; justify-content: space-between"
      >
        <div class="backletter">
          <div style="text-align: center; font-size: 22px">投标保函</div>
          <div style="text-align: right; margin-right: 20px">
            保函编号:
            <span class="pivotal">以保函实际编号为准</span>
          </div>
          <p>申请人：{{ this.form.warrantee }}</p>
          <p>地址：{{ this.warranteeAddress }}</p>
          <p>受益人：{{ this.form.beneficiary }}</p>
          <p>地址：{{ this.beneficiaryAddress }}</p>
          <p>开立人：河北永信正德非融资担保有限公司</p>
          <p>地址：石家庄高新区珠峰大街169号想象国际北区1-1-904</p>
          <p>
            致：
            <span class="pivotal">{{ this.form.beneficiary }}</span
            >（受益人名称）
          </p>
          <p class="text">
            我方（即“开立人”）已获得通知，本保函申请人（即“投标人”）已响应贵方__年__月__日就
            <span class="pivotal">{{ this.form.projectName }}</span
            >（以下简称“本工程”）发出的招标文件，并已向招标人（即“受益人”）提交了投标文件（即“基础交易”）。
          </p>
          <p class="text">
            一、我方理解根据招标条件，投标人必须提交一份投标保函（以下简称“本保函”），
            以担保投标人诚信履行其在上述基础交易中承担的投标人义务。鉴此，应申请人要求，
            我方在此同意向贵方出具此投标保函，本保函担保金额最高不超过人民币（大写）
            <span class="pivotal">{{ this.form.gua_money_upper }}</span>
            （
            <span class="pivotal">{{ this.form.money }}元</span>）。
          </p>
          <p class="text">二、我方在投标人发生以下情形时承担保证担保责任：</p>
          <p class="text">（1）投标人在开标后和投标有效期满之前撤销投标的；</p>
          <p class="text">
            （2）投标人在收到中标通知后，不能或拒绝在中标通知书规定的时间内与贵方签订合同；
          </p>
          <p class="text">
            （3）投标人在与贵方签订合同后，未在规定的时间内提交符合招标文件要求的履约担保；
          </p>
          <p class="text">（4）投标人违反招标文件规定的其他情形。</p>
          <p class="text">
            三、本保函为不可撤销、不可转让的见索即付独立保函。本保函有效期自开立之日起至投标有效期届满之日后的30日。投标有效期延长的，本保函有效期相应顺延，最迟不超过
            <span class="pivotal">{{ this.form.bid_end_date }}</span
            >。
          </p>
          <p class="text">
            四、我方承诺，在收到受益人发来的书面付款通知后的15日内无条件支付，前述书面付款通知即为付款要求之单据，且应满足以下要求：
          </p>
          <p class="text">（1）付款通知到达的日期在本保函的有效期内；</p>
          <p class="text">（2）载明要求支付的金额；</p>
          <p class="text">
            （3）载明申请人违反招投标文件规定的义务内容和具体条款；
          </p>
          <p class="text">
            （4）声明不存在招标文件规定或我国法律规定免除申请人或我方支付责任的情形；
          </p>
          <p class="text">
            （5）书面付款通知应在本保函有效期内到达的地址是：
            <span class="pivotal">
              石家庄高新区珠峰大街169号想象国际北区1-1-904 联系人：师美琦
              电话：0311-85341904。
            </span>
          </p>
          <p class="text">
            受益人发出的书面付款通知应由其为鉴明受益人法定代表人（负责人）或授权代理人签字并加盖公章。
          </p>
          <p class="text">
            五、本保函项下的权利不得转让，不得设定担保。贵方未经我方书面同意转让本保函或其项下任何权利，对我方不发生法律效力。
          </p>
          <p class="text">
            六、本保函项下的基础交易不成立、不生效、无效、被撤销、被解除，不影响本保函的独立有效。
          </p>
          <p class="text">
            七、受益人应在本保函到期后的七日内将本保函正本退回我方注销，但是不论受益人是否按此要求将本保函正本退回我方，我方在本保函项下的义务和责任均在保函有效期到期后自动消灭。
          </p>
          <p class="text">
            八、本保函适用的法律为中华人民共和国法律，争议裁判管辖地为中华人民共和国
            <span class="pivotal">石家庄市</span>。
          </p>
          <p class="text">
            九、本保函自我方法定代表人或授权代表签字并加盖公章之日起生效。
          </p>
          <p>
            开立人：
            <span class="pivotal">河北永信正德非融资担保有限公司</span>（公章）
          </p>
          <p>
            法定代表人（或授权代表人）：
            <span class="pivotal">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            （签字）
          </p>
          <p>
            地址：
            <span class="pivotal"
              >石家庄高新区珠峰大街169号想象国际北区1-1-904</span
            >
          </p>
          <p>
            邮政编码：
            <span class="pivotal">050000</span>
          </p>
          <p>
            电话：
            <span class="pivotal">0311-85341904</span>
          </p>
          <p>
            传真：
            <span class="pivotal">0311-85341904</span>
          </p>
          <p>
            开立时间：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日
          </p>
        </div>
        <div class="information">
          <div>
            <span style="margin-left: 20px; color: #333; font-weight: 700">
              <i class="el-icon-more" style="transform: rotate(90deg)"></i
              >项目信息
            </span>
            <el-form
              ref="form"
              :model="form"
              label-width="120px"
              style="margin-top: 20px"
            >
              <el-form-item label="项目名称">
                <span class="befor_1" style="left: -80px">*</span>
                <el-input v-model="form.projectName" disabled></el-input>
              </el-form-item>
              <el-form-item label="受益人">
                <el-input v-model="form.beneficiary" disabled></el-input>
              </el-form-item>
              <el-form-item label="被保证人">
                <el-input v-model="form.warrantee" disabled></el-input>
              </el-form-item>
              <el-form-item label="担保金额">
                <span class="befor_1" style="left: -80px">*</span>
                <el-input v-model="form.amountGuaranteed" disabled></el-input>
              </el-form-item>
              <el-form-item label="开标日期">
                <el-input v-model="form.bidOpeningDate" disabled></el-input>
              </el-form-item>
              <el-form-item label="投标有效期">
                <span class="befor_1" style="left: -95px">*</span>
                <el-input
                  v-model="form.periodOfValidity"
                  disabled
                  @input="period(form.periodOfValidity)"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input>
              </el-form-item>
              <el-form-item label="保函延长期">
                <span class="befor_1" style="left: -95px">*</span>
                <el-input
                  disabled
                  v-model="form.extraPeriod"
                  @input="lengthen(form.extraPeriod)"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input>
              </el-form-item>
              <el-form-item label="有效期">
                <el-input v-model="form.bid_end_date" disabled></el-input>
              </el-form-item>
              <el-form-item label="招标文件">
                <span class="befor_1" style="left: -80px">*</span>
                <el-upload
                  class="upload-demo"
                  action="api/newGuarantee.do?callBidFileUpLoad"
                  name="callBidsFile"
                  :on-success="success"
                  accept=".pdf"
                  :limit="1"
                  ref="uploadZ"
                  v-if="!isShow"
                >
                  <el-button size="small">上传文件</el-button>
                </el-upload>
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  :content="this.file"
                  placement="top"
                  v-if="isShow"
                > -->
                <div style="width: 100%" v-if="isShow">招标文件</div>
                <!-- </el-tooltip> -->
              </el-form-item>
              <el-form-item label="补遗文件">
                <el-upload
                  class="upload-demo"
                  action="api/newGuarantee.do?callBidFileUpLoad"
                  name="addendumFile"
                  :on-success="addendumFile"
                  accept=".pdf"
                  :limit="1"
                  ref="uploadB"
                >
                  <el-button size="small">上传文件</el-button>
                </el-upload>
              </el-form-item>
              <span
                style="
                  margin-left: 20px;
                  margin-top: 20px;
                  color: #333;
                  font-weight: 700;
                "
              >
                <i class="el-icon-more" style="transform: rotate(90deg)"></i
                >申请人
              </span>
              <el-form-item label="姓名" style="margin-top: 20px">
                <span class="befor_1" style="left: -50px">*</span>
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <span class="befor_1" style="left: -80px">*</span>
                <el-input v-model="form.phone"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div style="margin-top: 50px; text-align: center">
        <el-button type="primary" @click="submit">下一步</el-button>
      </div>
    </el-drawer>

    <el-dialog
      title="保函邀约函预览"
      :visible.sync="dialogFormVisible"
      width="50%"
      top="7vh"
    >
      <iframe
        :src="'https://www.haneton.com/guarantee/' + filePath"
        frameborder="0"
        style="width: 100%; height: 600px"
      ></iframe>
      <div style="text-align: center; margin-top: 20px">
        <el-checkbox v-model="checked">我已阅读并同意该邀约函内容</el-checkbox>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <el-button type="primary" :disabled="!checked" @click="payment"
          >支付</el-button
        >
      </div>
    </el-dialog>

    <!-- 支付页面 -->
    <el-dialog
      title="支付页"
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <iframe
        :src="this.content"
        frameborder="0"
        width="100%"
        height="620px"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
//这里导入其他文件（组件，工具js，第三方插件js，json文件，图片文件等等）
//import 《组件名称》 from '《组件路径》';
import downloadjs from "downloadjs";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},

  // 组件名称
  name: "",

  // 这里存放数据
  data() {
    return {
      warranteeAddress: null,
      beneficiaryAddress:null,
      projectName: null,
      tenderee: null,
      area: null,
      areaArr: [],
      date: null,
      tableData: [],
      total: 0,
      size: 10,
      current: 1,
      drawer: false,
      form: {
        projectName: "",
        beneficiary: "",
        warrantee: "",
        amountGuaranteed: "",
        bidOpeningDate: "",
        periodOfValidity: "",
        extraPeriod: "",
        name: "",
        phone: "",
        bid_end_date: "",
        gua_money_upper: "",
      },
      firmName: "",
      CAKeyEncStr: "",
      exists: "",
      file: "",
      omitFileUrl: "",
      dialogVisible: false,
      content: "",
      projectNo: "",
      biaoduanName: "",
      biaoduanCode: "",
      isShow: false,
      dialogFormVisible: false,
      checked: false,
      orderNum: "",
      filePath: "",
    };
  },

  // 监听属性 类似于data概念
  computed: {},

  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    search() {
      this.current = 1;
      this.getDataList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDataList();
    },

    // 获取数据列表
    getDataList() {
      var date = "";
      if (this.date == "" || this.date == null) {
        date = null;
      } else {
        date = this.moment(this.date).format("YYYY-MM-DD");
      }
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?getProjectList",
        params: {
          page: this.current,
          pageNum: this.size,
          from: "宿州",
          projectName: this.projectName,
          tenderee: this.tenderee,
          province: this.area,
          projectBidDate: date,
        },
      }).then((res) => {
        if (res.data.status == 0) {
          this.tableData = res.data.data.items;
          this.total = res.data.data.count;
        } else {
          this.$message({
            message: res.data.statusInfo,
            type: "error",
          });
        }
      });
    },

    // 点击重置
    reset() {
      this.date = "";
      this.projectName = "";
      this.tenderee = "";
      this.area = "";
      this.current = 1;
      this.getDataList();
    },

    // 获取所有省份
    getAllProvinces() {
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?getProvince",
      }).then((res) => {
        this.areaArr = res.data.obj;
      });
    },

    getEnterpriseMessage() {
      var type = window.sessionStorage.getItem("type");
      if (type == "CA") {
        var params = {
          CAKeyId: this.firmName,
          from: 10,
          CAKeyEncStr: this.CAKeyEncStr,
        };
      } else if (type == "CFCA") {
        var params = {
          CAKeyId: this.firmName,
          from: 10,
          CAKeyEncStr: window.sessionStorage.getItem("content"),
        };
      } else {
        var params = {
          handsetNum: window.sessionStorage.getItem("phone"),
          from: 10,
        };
      }
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?existEnterpriseInfo",
        params: params,
      }).then((res) => {
        if (
          res.data.data.userStatus == "1" ||
          (res.data.data.userStatus == "3" && !res.data.data.apply)
        ) {
          this.$confirm("企业信息未完善,请先完善企业信息", "提示", {
            confirmButtonText: "确定",
            closeOnPressEscape: false,
            closeOnClickModal: false,
            closeOnHashChange: false,
            showCancelButton: false,
            showClose: false,
            type: "warning",
          }).then(() => {
            if (type == "account") {
              this.$router.push("/enterprise");
            } else {
              this.$router.push("/message");
            }
          });
        } else if (res.data.data.userStatus == "3" && res.data.data.apply) {
          this.$confirm(
            res.data.data.errorInfo + "，请先完善企业信息",
            "提示",
            {
              confirmButtonText: "确定",
              closeOnPressEscape: false,
              closeOnClickModal: false,
              closeOnHashChange: false,
              showCancelButton: false,
              showClose: false,
              type: "warning",
            }
          ).then(() => {
            if (type == "account") {
              this.$router.push("/enterprise");
            } else {
              this.$router.push("/message");
            }
          });
        } else {
          this.warranteeAddress=res.data.data.info.com_address
        }
      });
    },
    editDate(e) {
      console.log(e);
      var date1 = new Date(e);
      date1.setDate(date1.getDate() - 1)
      // var year = date1.getFullYear();
      // var month = date1.getMonth() + 1;
      // var day = date1.getDate() - 1;
      // if (month < 10) {
      //   month = "0" + month;
      // }
      // if(day==0){
      //   month=month-1
      //   if()
      // }
      // if (day < 10) {
      //   day = "0" + day;
      // }
      this.form.bid_end_date=date1.toISOString().substring(0, 10)
      // this.form.bid_end_date = year + "-" + month + "-" + day;
    },
    // 点击立即申请
    applyImmediately(e) {
      if (window.sessionStorage.getItem("type") == "account") {
        this.form.warrantee = window.sessionStorage.getItem("com_name");
      } else {
        this.form.warrantee = this.firmName;
      }
      if (e.profits_person_address) {
        this.beneficiaryAddress=e.profits_person_address
      } else {
        this.beneficiaryAddress="宿州市"
      }
      this.form.projectName = e.biaoduanName;
      this.form.beneficiary = e.profits_person;
      this.form.amountGuaranteed = e.gua_money;
      this.form.bidOpeningDate = e.bid_begin_date;
      console.log("this.form.bidOpeningDate",this.form.bidOpeningDate);
      this.form.periodOfValidity = e.bid_validity;
      this.form.extraPeriod = e.yancahng_date;
      this.editDate(e.bid_end_date);
      // var date1 = new Date(e.bid_end_date);
      // var year=date1.getFullYear();
      // var month=date1.getMonth()+1;
      // var day=date1.getDate()-1;
      // if(month<10){
      // 	month="0"+month;
      // }
      // if(day<10){
      // 	day="0"+day;
      // }
      // this.form.bid_end_date =year+"-"+month+"-"+day;
      // this.form.bid_end_date = e.bid_end_date;
      this.form.gua_money_upper = e.gua_money_upper;
      this.exists = e.tender_file_exist;
      this.projectNo = e.projectNo;
      this.biaoduanName = e.biaoduanName;
      this.biaoduanCode = e.biaoduanCode;
      var encStr =
        '{"callBidFile": "' +
        this.file +
        '", "omitFileUrl":"' +
        this.omitFileUrl +
        '", "projectName":"' +
        this.form.projectName +
        '", "beneficiary":"' +
        this.form.beneficiary +
        '","bidOpenDate":"' +
        this.form.bidOpeningDate +
        '","bidInDate":"' +
        this.form.periodOfValidity +
        '","extraPeriod":"' +
        this.form.extraPeriod +
        '","projectNo":"' +
        this.projectNo +
        '","biaoduanName":"' +
        this.biaoduanName +
        '","biaoduanCode":"' +
        this.biaoduanCode +
        '"}';
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?checkRepeatOrder",
        params: {
          from: 10,
          encStr: encStr,
          CAKeyId: this.firmName,
        },
      }).then((res) => {
        if (res.data.status == -18) {
          this.$message({
            message: "您已申请过此保函，请在保函历史查看",
            type: "error",
          });
        } else if (res.data.status == 0) {
          this.axios({
            method: "GET",
            url: "/newGuarantee.do?saveTenderingFile",
            params: {
              p: e.id,
            },
          }).then((res) => {
            if (res.data.attributes == null) {
              this.isShow = false;
              return;
            } else {
              this.isShow = true;
              this.file = res.data.attributes.filePath;
            }
          });
          this.drawer = true;
        }
      });
    },

    // 上传文件成功回调
    success(response) {
      if (response.success == true) {
        this.$message({
          message: "上传文件成功!",
          type: "success",
        });
        this.file = response.attributes.filePath;
      } else {
        this.$message({
          message: "上传文件失败，" + response.msg,
          type: "error",
        });
      }
    },

    addendumFile(response) {
      if (response.success == true) {
        this.$message({
          message: "上传文件成功!",
          type: "success",
        });
        this.omitFileUrl = response.attributes.filePath;
      } else {
        this.$message({
          message: "上传文件失败，" + response.msg,
          type: "error",
        });
      }
    },

    // 用户输入投标有效期
    period(e) {
      var date = this.moment(this.form.bidOpeningDate)
        .add(e, "days")
        .add(this.form.extraPeriod, "days")
        .format("YYYY-MM-DD");
      this.form.bid_end_date = date;
      this.editDate(this.form.bid_end_date);
    },

    // 用户输入保函延长期
    lengthen(e) {
      var date = this.moment(this.form.bidOpeningDate)
        .add(e, "days")
        .add(this.form.periodOfValidity, "days")
        .format("YYYY-MM-DD");
      this.form.bid_end_date = date;
      this.editDate(this.form.bid_end_date);
    },

    // 点击下一步
    submit() {
      if (this.file == "") {
        this.$message({
          message: "未上传招标文件",
          type: "warning",
        });
        return;
      } else if (this.form.name == "" || this.form.phone == "") {
        this.$message({
          message: "请填写申请人信息",
          type: "warning",
        });
        return;
      } else if (
        this.form.projectName == "" ||
        this.form.amountGuaranteed == "" ||
        this.form.periodOfValidity == "" ||
        this.form.extraPeriod == ""
      ) {
        this.$message({
          message: "项目信息不可为空",
          type: "warning",
        });
        return;
      } else {
        var encStr =
          '{"callBidFile": "' +
          this.file +
          '", "omitFileUrl":"' +
          this.omitFileUrl +
          '", "projectName":"' +
          this.form.projectName +
          '", "beneficiary":"' +
          this.form.beneficiary +
          '","bidOpenDate":"' +
          this.form.bidOpeningDate +
          '","bidInDate":"' +
          this.form.periodOfValidity +
          '","extraPeriod":"' +
          this.form.extraPeriod +
          '","projectNo":"' +
          this.projectNo +
          '","biaoduanName":"' +
          this.biaoduanName +
          '","biaoduanCode":"' +
          this.biaoduanCode +
          '"}';
        this.axios({
          method: "GET",
          url: "/newGuarantee.do?addGuaranteeOrder",
          params: {
            encStr: encStr,
            from: 10,
            cashDeposit: this.form.amountGuaranteed,
            CAKeyId: this.firmName,
            sysCode: "A01A08",
            applicantName: this.form.name,
            applicantPhone: this.form.phone,
            guaranteeType: 2,
          },
        }).then((res) => {
          if (res.data.status == 0) {
            this.orderNum = res.data.data.orderNum;
            this.axios({
              method: "GET",
              url: "/newGuarantee.do?findInvitationLette",
              params: {
                orderNum: this.orderNum,
                from: 10,
                CAKeyId: this.firmName,
              },
            }).then((res) => {
              if (res.data.status == 0) {
                this.filePath = res.data.data.relative_path;
                this.payment();
                // this.dialogFormVisible = true;
              } else {
                this.$message({
                  message: res.data.statusInfo,
                  type: "error",
                });
              }
            });
          } else if (res.data.status == -18) {
            this.$message({
              message: "您已申请过此保函，请在保函历史查看",
              type: "error",
            });
          } else {
            this.$message({
              message: res.data.statusInfo,
              type: "error",
            });
          }
        });
      }
    },

    payment() {
      var CAType = "10_3";
      this.content =
        "https://www.haneton.com/hetinterface/hetInterfaceController.do?goPay4Gs&orderNum=" +
        this.orderNum +
        "&CAUkeyId=" +
        this.firmName +
        "&comId=A01A08&guaFee=" +
        this.form.amountGuaranteed +
        "&fromType=" +
        10 +
        "&CAType=" +
        CAType +
        "&guaType=" +
        2;
      this.dialogFormVisible = false;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$router.push("/history");
    },
    closed() {
      this.form.projectName = "";
      this.form.beneficiary = "";
      this.form.warrantee = "";
      this.form.amountGuaranteed = "";
      this.form.bidOpeningDate = "";
      this.form.periodOfValidity = "";
      this.form.extraPeriod = "";
      this.form.name = "";
      this.form.phone = "";
      this.form.bid_end_date = "";
      this.form.gua_money_upper = "";
      this.$refs["uploadZ"].clearFiles();
      this.$refs["uploadB"].clearFiles();
    },

    // 操作手册
    operationManual() {
      window.open("https://ejlb673kl8.feishu.cn/wiki/wikcnjVAIfeV1fZkcPvyY0lhHGd")
      // var operationManualPdf = "https://www.suzhou.haneton.com/manualsSZ.pdf";
      // downloadjs(operationManualPdf);
    },

    // 点击返回
    getBack() {
      this.$router.push("/main");
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getDataList();
    this.getAllProvinces();
    this.firmName = window.sessionStorage.getItem("firmName");
    this.CAKeyEncStr = window.sessionStorage.getItem("date");
    this.getEnterpriseMessage();
  },

  // 生命周期 - 创建之前
  beforeCreate() {},

  // 生命周期 - 挂载之前
  beforeMount() {},

  // 生命周期 - 更新之前
  beforeUpdate() {},

  // 生命周期 - 更新之后
  updated() {},

  // 生命周期 - 销毁之前
  beforeDestroy() {},

  // 生命周期 - 销毁完成
  destroyed() {},

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // keep-alive缓存离开之后触发
  deactivated() {},
};
</script>

<style scoped>
.online {
  height: 100%;
}
.cardTop-middle {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  background-color: #fff;
  height: 153px;
}
.content {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  border: 1px solid rgb(51, 126, 190);
  text-align: center;
  color: rgb(51, 126, 190);
}
.explain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.part {
  text-align: center;
  font-size: 12px;
  color: rgb(51, 126, 190);
}
.backletter {
  width: 50%;
  height: 700px;
  overflow-y: auto;
  line-height: 30px;
  margin-top: 20px;
}
.information {
  width: 45%;
  height: 700px;
  border-left: 1px solid #ccc;
}
.el-form-item {
  margin-bottom: 12px;
}
.text {
  text-indent: 2em;
}
.pivotal {
  text-decoration: underline;
}
.befor_1 {
  position: absolute;
  color: #f56c6c;
}
</style>
